<template>
  <div class="map-around-wrapper">
    <div class="content-wrapper">
      <m-map ref="map" :showAreaSearch="true" :aroundType="aroundType" @areaSearch="handleAreaSearch" @showPoint="handleShowPointDetail"></m-map>
      <div class="bottom-content-wrapper">
        <div class="area-search-wrapper" :style="{ top: '-24px' }">
          <van-tabs v-model="distance" type="card" @click="triggerAreaSearch">
            <van-tab title="500m以内" :name="500"></van-tab>
            <van-tab title="1000m以内" :name="1000"></van-tab>
          </van-tabs>
        </div>
        <div class="btn-list" :class="{'result-style': status==='item' }">
          <div class="icon-favorite mb48" @click="toFavorite"></div>
          <div class="icon-location" @click="location"></div>
        </div>
        <div class="result-content-wrapper">
          <keep-alive>
            <transition name="van-slide-up" mode="out-in">
              <div class="search-result-wrapper" v-if="status === 'hide'" @click="toggleShowResultList('list')">
                <div class="expand-btn">
                  <i class="iconfont iconweibiaoti-1"></i>
                </div>
                搜索范围内共找到<span>{{total}}</span>{{aroundTypeStr}}
              </div>
              <van-list
                ref="ListGroup"
                class="result-list-wrapper"
                v-else-if="status === 'list'"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="loadMore"
              >
                <div class="expand-btn" @click="toggleShowResultList('hide')">
                  <i class="iconfont iconweibiaoti-1 close"></i>
                </div>
                <navigate-cell
                  class="cell"
                  :class="{'border-bottom-1px': index < pageData.length - 1}"
                  v-for="(item, index) in pageData"
                  @click.native="handleMoveToPoint(item)"
                  @favorite="handleFavorite(item)"
                  @navigate="handleNavigate(item)"
                  :key="item.id"
                  :title="item.name"
                  :label="item.address"
                  :distance="item.distance"
                  :isFavorite="item.isFavorite"
                  >
                  <template slot="desc">
                    <div class="desc-info" v-if="aroundType === 3">
                      <span>可借<i class="color-red ml5"> {{ Math.ceil(Math.random() * 20) }}</i></span>
                      <span>可还 <i class="color-green ml5"> {{ Math.ceil(Math.random() * 20) }}</i></span>
                    </div>
                    <div class="desc-info" v-if="aroundType === 2">
                      <span>剩余车位<i class="color-red ml5">{{6}}</i></span>
                    </div>
                  </template>
                </navigate-cell>
              </van-list>
              <div class="selection-item" v-else>
                <div class="expand-btn" @click="toggleShowResultList('hide')">
                  <i class="iconfont iconweibiaoti-1 close"></i>
                </div>
                <navigate-cell
                  class="cell"
                  @favorite="handleFavorite(pointDetail)"
                  @navigate="handleNavigate(pointDetail)"
                  :title="pointDetail.name"
                  :label="pointDetail.address"
                  :distance="pointDetail.distance"
                  :isFavorite="pointDetail.isFavorite"
                  >
                    <template slot="desc">
                      <div class="desc-info" v-if="aroundType === 3">
                        <span>可借<i class="color-red ml5"> {{ Math.ceil(Math.random() * 20) }}</i></span>
                        <span>可还 <i class="color-green ml5"> {{ Math.ceil(Math.random() * 20) }}</i></span>
                      </div>
                      <div class="desc-info" v-if="aroundType === 2">
                        <span>剩余车位<i class="color-red ml5">{{6}}</i></span>
                      </div>
                    </template>
                  </navigate-cell>
              </div>
            </transition>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
import { saveLoading } from '@/utils/utils'

import MyMap from '@/components/MyMap'
const { addPoiCollect, deletePoiCollect } = API

export default {
  name: 'mapAround',
  data () {
    return {
      status: 'hide', // hide, list, item
      distance: 500,
      loading: false,
      page: 1,
      pageSize: 20,
      total: 0,
      allData: [],
      pointDetail: {}, // 点位详情
      stationType: 3, // 驿站类型
      selectStation: 0
    }
  },
  beforeRouteLeave (to, from, next) {
    // 如果页面离开是去收藏页面则缓存该页面否则不缓存
    if (to.name === 'favorites') {
      from.meta.cache = true
    } else {
      from.meta.cache = false
    }
    next()
  },
  computed: {
    // 周边类型
    aroundType () {
      let type = this.$route.params.type
      if (type === 'wc') {
        return 1
      } else if (type === 'car') {
        return 2
      } else if (type === 'cycle') {
        return 3
      } else {
        return this.stationType
      }
    },
    // 周边类型名称
    aroundTypeStr () {
      if (this.aroundType === 1) {
        return '厕所'
      } else if (this.aroundType === 2) {
        return '停车场'
      } else if (this.aroundType === 3) {
        return '自行车'
      } else {
        return '结果'
      }
    },
    // 分页数据
    pageData () {
      return this.allData.slice()
    },
    // 是否全部加载完成
    finished () {
      return this.total <= this.pageData.length
    }
  },
  activated () {
    let type = this.$route.params.type
    let title = ''
    if (type === 'wc') {
      title = '找找厕所'
    } else if (type === 'car') {
      title = '找找车位'
    } else if (type === 'cycle') {
      title = '天天骑车'
    } else {
      title = '便民驿站'
    }
    document.title = title
  },
  methods: {
    // 定位
    location () {
      this.$refs.map.getGeoLocation()
    },
    // 跳转到收藏页面
    toFavorite () {
      this.$router.push('/favorites/' + this.$route.params.type)
    },
    // 执行区域搜索
    triggerAreaSearch (val = 500) {
      this.total = 0
      this.page = 1
      this.status = 'hide'
      this.allData = []
      this.$refs.map.areaSearch(val)
    },
    // 区域搜索
    handleAreaSearch ({ total, data }) {
      if (total === 0) {
        this.allData = []
        this.status = 'hide'
        this.$refs.map.addMassMarks(this.allData, 0)
        return
      }
      if (this.allData.length < total) {
        this.total = total
        this.allData.push(...data)
        this.$refs.map.addMassMarks(this.allData, this.allData[0].style)
      }
    },
    // 切换是否显示结果列表
    toggleShowResultList (flag) {
      this.status = flag
      // 如果没有结果则不显示
      // if (flag && this.total === 0) return
      // this.showResultList = flag
      // if (flag === false) {
      //   this.isShowDetail = false
      // }
    },
    // 加载更多
    loadMore () {
      if (this.allData.length > 0 && this.$refs.ListGroup.$children.length === 0) {
        this.loading = false
        return
      }
      this.$nextTick(() => {
        this.loading = true
        this.page++
        this.$refs.map.areaSearch(this.distance, this.page).then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      })
    },
    // 显示点位详情
    handleShowPointDetail (data) {
      this.pointDetail = data
      this.status = 'item'
    },
    // 移动到点位所在位置
    handleMoveToPoint (data) {
      this.$refs.map.handleMassClick({ data })
    },
    // 添加或取消收藏
    handleFavorite (pointData) {
      let data = {}
      let api = ''
      let successMsg = ''

      if (pointData.isFavorite) {
        // 取消收藏
        data = {
          poiId: pointData.id,
          type: this.aroundType
        }
        api = deletePoiCollect
        successMsg = '取消收藏成功'
      } else {
        // 收藏
        data = {
          poiId: pointData.id,
          type: this.aroundType,
          latitude: pointData.latitude,
          longitude: pointData.longitude,
          name: pointData.name,
          poiAddr: pointData.address
        }
        api = addPoiCollect
        successMsg = '收藏成功'
      }

      saveLoading({
        data,
        api,
        successMsg,
        success: () => {
          this.$set(pointData, 'isFavorite', !pointData.isFavorite)
        }
      })
    },
    // 导航
    handleNavigate ({ latitude, longitude, name, address }) {
      this.$refs.map.handleNavigate({ latitude, longitude, name, address })
    }
  },
  components: {
    'm-map': MyMap,
    'navigate-cell': () => import('@/components/NavigateCell.vue')
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .map-around-wrapper{
    .content-wrapper{
      ::v-deep .map-wrapper{
        .search-wrapper{
          top: 32px;
        }
      }
    }
    .bottom-content-wrapper{
      position: absolute;
      right: 0;
      left: 0;
      bottom: 48px;
      .result-content-wrapper{
        position: relative;
        width: 100vw;
        .search-result-wrapper{
          width: 100%;
          height: 112px;
          box-sizing: border-box;
          background:rgba(255,255,255,1);
          box-shadow:0px 4px 10px 0px rgba(221,221,221,0.5);
          border-radius:8px;
          color: #999;
          line-height: 112px;
          text-align: center;
          font-size: 26px;
          padding-top: 22px;
          span{
            color: #FC5E25;
          }
        }
        .result-list-wrapper{
          max-height: 560px;
          overflow: auto;
          background: #fff;
          border-radius: 8px;
          box-shadow:0px 4px 10px 0px rgba(221,221,221,0.5);
          padding: 64px 24px 0;
          .cell{
            border-radius: 0px;
            box-shadow: none;
            padding: 0 0 30px;
            .desc-info{
              font-size: 24px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;
              line-height: 34px;
              .color-red{
                color: #E82447;
              }
              .color-green{
                color: #148DFF;
              }
            }
          }
        }
        .expand-btn{
          position: absolute;
          top: 0;
          left: 0px;
          z-index: 99;
          width: 100%;
          height: 64px;
          line-height: 64px;
          box-sizing: border-box;
          text-align: center;
          background: #fff;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          .iconfont{
            color: #ccc;
            font-size: 40px;
            display: block;
            &.close{
              transform: rotate(180deg);
            }
          }
        }
        .selection-item{ // 选中的项目样式
          width: 100vw;
          box-sizing: border-box;
          overflow: auto;
          background: #fff;
          border-radius: 8px;
          box-shadow:0px 4px 10px 0px rgba(221,221,221,0.5);
          padding: 64px 24px 0;
          .cell{
            border-radius: 0px;
            box-shadow: none;
            padding: 0 0 30px;
            .desc-info{
              font-size: 24px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;
              line-height: 34px;
              .color-red{
                color: #E82447;
              }
              .color-green{
                color: #148DFF;
              }
            }
          }
        }
      }

      .area-search-wrapper{
        height: 72px;
        line-height: 72px;
        position: absolute;
        top: -24px;
        left: 50%;
        transform: translate(-50%, -100%);
        // text-align: center;
        // background:#0482FF;
        // box-shadow: 0px 4px 10px 0px rgba(221,221,221,0.5);
        // border-radius: 30px;
        // font-size: 28px;
        // color: #fff;
        .van-tabs {
          width: 560px;
          ::v-deep .van-tabs__wrap{
            height: 72px;
            .van-tab{
              width: 280px;
              height: 72px;
              border: none;
              color: #999;
              &.van-tab--active{
                color: #fff;
              }
            }
            .van-tabs__nav--card{
              border: none;
              height: 72px;
            }
          }
        }
        &.has-search{
          top: -140px;
        }
      }

      .btn-list{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: -120px;
        right: 24px;
        transform: translateY(calc(-100% - 42px));
        &.result-style{
          transform: translateY(calc(-100% - 100px));
        }
        .icon-favorite{
          @include bgImage('~@/assets/collection', 52px, 52px);
        }
        .icon-location{
          @include bgImage('~@/assets/weizhi', 52px, 52px);
        }
        .btn{
          width: 86px;
          height: 86px;
          background: #fff;
          border-radius: 50%;
          line-height: 86px;
          text-align: center;
          box-shadow: 0 0 20px #ccc;
          margin-top: 32px;
          .iconfont{
            font-size: 44px;
            color: #677881;
            &.iconshoucang{
              color: #0482FF;
            }
            &.iconshangdian{
              color: #4FD0B4;
            }
          }
        }
      }
    }
    .station-modal-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .modal-content{
        width: 702px;
        box-sizing: border-box;
        padding: 28px;
        background:#fff;
        box-shadow: 0px 4px 10px 0px rgba(221,221,221,0.5);
        border-radius: 12px;
        position: relative;
        .close-btn{
          padding: 12px;
          position: absolute;
          top: 32px;
          right: 32px;
          transform: translate(12px, -24px);
          .iconfont{
            font-size: 24px;
            color: #999;
          }
        }
        h4{
          color: #333;
          font-size: 32px;
          font-weight: 600;
          margin-bottom: 56px;
        }
        .station-list-wrapper{
          display: flex;
          flex-wrap: wrap;
          padding-left: 32px;
          .station{
            width:172px;
            box-sizing: border-box;
            height:68px;
            border-radius: 4px;
            font-size: 28px;
            line-height: 68px;
            text-align: center;
            margin-right: 30px;
            border: 1px solid #999;
            color: #666;
            margin-bottom: 42px;
            &.active{
              background: #0482FF;
              border: none;
              color: #fff;
            }
            &:nth-child(3n){
              margin-right: 0px;
            }

          }
        }
        .save-btn{
          width:572px;
          height:88px;
          background: #0482FF;
          color: #fff;
          line-height: 88px;
          text-align: center;
          margin: 30px auto 0;
          border-radius: 8px;
          box-shadow: 0px 4px 8px 0px rgba(4,130,255,0.3);

        }
      }
    }
  }
</style>
