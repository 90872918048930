<template>
  <div class="approveSearch">
    <iframe src="http://banshi.beijing.gov.cn/pubtask/grfw.html?locationCode=110115000000" id="iframeLink" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  mounted () {
    let iframe = document.querySelector('#iframeLink')
    iframe.addEventListener('load', () => {
      window.scrollTo({
        top: 1046,
        behavior: 'smooth'
      })
    })
  }
}
</script>
<style lang="scss">
.approveSearch{
  width: 100vw;
  height: 100vh;
  iframe{
    height: 4492px;
    width: 100vw;
  }
}
</style>
